import { Component, OnInit } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection
} from "angularfire2/firestore";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.css"]
})
export class ContactComponent implements OnInit {
  success: boolean = false;

  error: boolean = false;

  contactForm: FormGroup;

  contactsCollection: AngularFirestoreCollection<any>;

  constructor(private afs: AngularFirestore, private fb: FormBuilder) {
    this.contactsCollection = this.afs.collection<any>("contacts");

    this.contactForm = this.fb.group({
      name: ["", Validators.required],
      email: ["", Validators.compose([Validators.required, Validators.email])],
      subject: ["", Validators.required],
      message: ["", Validators.required]
    });
  }

  ngOnInit() {}

  contactFormSubmit() {
    this.contactsCollection
      .add(this.contactForm.value)
      .then(() => {
        this.contactForm.reset();
        this.success = true;
      })
      .catch(() => {
        this.error = true;
      });
  }
}
