import { Component, OnInit, ElementRef, Renderer2 } from "@angular/core";
import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcStatusChangeEvent,
  NgcNoCookieLawEvent
} from "ngx-cookieconsent";
import {
  Router,
  NavigationStart,
  NavigationCancel,
  NavigationEnd
} from "@angular/router";
import { Subscription } from "rxjs/Subscription";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private ccService: NgcCookieConsentService
  ) {}

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        setTimeout(() => {
          this.renderer.addClass(
            this.el.nativeElement.querySelector(".porta_tm_home_title_wrap"),
            "appear"
          );
        }, 500);
      }
    });
  }
}
