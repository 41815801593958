import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { environment } from "../environments/environment";
import { ReactiveFormsModule } from "@angular/forms";
import { AngularFireModule } from "angularfire2";
import { AngularFirestoreModule } from "angularfire2/firestore";
import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig
} from "ngx-cookieconsent";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ContactComponent } from "./contact/contact.component";
import { AboutComponent } from "./about/about.component";
import { ProductsComponent } from "./products/products.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { GetInTouchComponent } from "./get-in-touch/get-in-touch.component";
import { PortfolioMasonryComponent } from "./portfolio-masonry/portfolio-masonry.component";
import { LoadingScreenComponent } from "./loading-screen/loading-screen.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { HomeComponent } from "./home/home.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { TermsOfServiceComponent } from "./terms-of-service/terms-of-service.component";
import { CookiePolicyComponent } from "./cookie-policy/cookie-policy.component";

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: "tamarinlabs.com"
  },
  position: "bottom-right",
  theme: "block",
  palette: {
    popup: {
      background: "#000000",
      text: "#eeeeee",
      link: "#ffffff"
    },
    button: {
      background: "#ffffff",
      text: "#000000",
      border: "transparent"
    }
  },
  type: "info",
  content: {
    message:
      "This website uses cookies to ensure you get the best experience on our website.",
    dismiss: "Got it!",
    deny: "Refuse cookies",
    link: "Learn more",
    href: "https://www.tamarinlabs.com/cookie-policy"
  }
};

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    AboutComponent,
    ProductsComponent,
    HeaderComponent,
    FooterComponent,
    GetInTouchComponent,
    PortfolioMasonryComponent,
    LoadingScreenComponent,
    NotFoundComponent,
    HomeComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    CookiePolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
