import { Component, OnInit } from "@angular/core";
import { LoadingScreenService } from "./loading-screen.service";

@Component({
  selector: "app-loading-screen",
  templateUrl: "./loading-screen.component.html",
  styleUrls: ["./loading-screen.component.css"]
})
export class LoadingScreenComponent implements OnInit {
  isVisible: boolean = true;

  constructor(private loadingScreenService: LoadingScreenService) {}

  ngOnInit() {
    this.loadingScreenService.visibility.subscribe(
      visibility => (this.isVisible = visibility)
    );
  }
}
