import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {
  Router,
  NavigationStart,
  NavigationCancel,
  NavigationEnd
} from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class LoadingScreenService {
  public visibility: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.visibility.next(true);
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel
      ) {
        setTimeout(() => {
          this.visibility.next(false);
        }, 1500);
      }
    });
  }

  resolve() {
    setTimeout(() => {
      this.visibility.next(false);
    }, 1500);
  }
}
