import { Component, HostListener, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/platform-browser";
import {
  Router,
  NavigationStart,
  NavigationCancel,
  NavigationEnd
} from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent {
  menuOpen: boolean = false;
  navIsFixed: boolean = false;

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const number =
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;
    if (number > 100) {
      this.navIsFixed = true;
    } else if (this.navIsFixed && number < 10) {
      this.navIsFixed = false;
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router
  ) {
    this.menuOpen = false;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.menuOpen = false;
      }
    });
  }

  toggleMenu() {
    this.menuOpen = this.menuOpen ? false : true;
  }
}
